$(document).ready(function () {

    function extendForm(names, fn) {
        (Array.isArray(names) ? names : [names]).forEach(function(name) {
            var old = window[name],
                id = name.substr(0, name.length - 9);
            if (id === 'ejgWTB') {
                id = 'ejgWhereToBuy';
            }
            
            window[name] = function(form, container) {
                if (!form) {
                    container = $('#' + id);
                    form = container.find('.form');
                }
                
                old.call(this, form, container, id);
                fn(form, container, id);
            };
        });
    }
    
    extendForm(['ejgContactUs_callback', 'ejgWTB_callback', 'ejgUnsubscribe_callback', 'ejgNewsletter_callback'], function(form, container, formName) {
        form.find('.row').removeClass('row').addClass('form-group');
        form.find('.dropdownTxt').remove();
        form.find('select').removeClass('fieldSelect').addClass('custom-select');
        form.find('input[type=text], input[type=email], .fieldTextArea').removeClass('fieldInput').addClass('form-control');
        form.find('.dropdownWrapper').removeClass('fieldInput dropdownWrapper');
        form.find('input#submit').addClass("btn btn-dark-simple mb-3")
        
        if (formName === 'ejgWhereToBuy') {
            form.find('.acctGrpWrapperRow').addClass('ml-0 mb-3');
            form.find('.acctGrpWrapper').addClass('custom-control custom-radio');
            form.find('.acctGrpWrapper .fieldInput').addClass('custom-control-input');
            form.find('.acctGrpWrapper .label').addClass('custom-control-label');
        }
        else if (formName === 'ejgNewsletter') {
            form.find('#signupInputWrapper').addClass('custom-control custom-checkbox');
        }
        else if (formName === 'ejgContactUs') {
            container.find('#formHeaderWrapper').removeClass('row');
            container.addClass('loaded');
            // custom checkboxes
            form.find('#userOpinionWrapper, #moreInfoWrapper, #userContactWrapper').addClass('custom-control custom-checkbox');
            form.find('#userOpinionWrapper .fieldInput, #moreInfoWrapper .fieldInput, #userContactWrapper .fieldInput').addClass('custom-control-input');
            form.find('#userOpinionWrapper .label, #moreInfoWrapper .label, #userContactWrapper .label').addClass('custom-control-label');
            // custom radio
            form.find('#prefContactEmail, #prefContactEmail + label').wrapAll('<div class="custom-control custom-radio"></div>');
            form.find('#prefContactPhone, #prefContactPhone + label').wrapAll('<div class="custom-control custom-radio"></div>');
            form.find('#prefContactMail, #prefContactMail + label').wrapAll('<div class="custom-control custom-radio"></div>');
            form.find('#prefContactMethodWrapper .radioField').addClass('custom-control-input');
            form.find('#prefContactMethodWrapper .label').addClass('custom-control-label');
        }
        else if(formName === 'ejgUnsubscribe') {
            form.find('input#submit').val('Unsubscribe');
        }
    });
    
    /**
     * Where To Buy Form
     */
    extendForm('ejgWTB_callback', function(form, container) {
        form.find('#acctGroup').hide();
    });
    
    /**
     * Contact Form
     */
    extendForm('ejgContactUs_callback', function(form, container) {
        container.find('h1:first').remove();
    });
    
    /**
     * Newsletter Form
     */
    extendForm('ejgNewsletter_callback', function(form, container) {
        container.find('h1:first').remove();
    });
    
    /**
     * Unsubscribe Form
     */
    extendForm('ejgUnsubscribe_callback', function(form, container) {
        container.find('h1:first').remove();
    });

});